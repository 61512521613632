import { Mesh, MeshBasicMaterial, Texture, TextureLoader } from "three";
import { ComponentDefinition } from "aframe";
import { ComponentDeclaration } from "./index";

export interface ImageSequenceAttributes {
  textureArray: Texture[];
}
export const ImageSequenceDefinition: ComponentDefinition<ImageSequenceAttributes> =
  {
    textureArray: [],
    schema: {
      images: {
        default: [1, 2, 3],
      },
      framerate: {
        default: 24,
      },
    },
    init() {
      console.log(this.data);

      const loader = new TextureLoader();
      this.textureArray = this.data.images.map((url: string) =>
        loader.load(url)
      );

      this.el.addEventListener("loaded", (e) => {
        let mesh = this.el.getObject3D("mesh") as Mesh;
        let material = mesh.material as MeshBasicMaterial;

        let i = 0;
        setInterval((e) => {
          // if we're at the last element - swap to the first one
          if (i >= this.textureArray.length) {
            i = 0;
          }
          material.map = this.textureArray[i++];
          material.needsUpdate = true;
        }, 1000 / this.data.framerate);
      });
    },
  };

const ImageSequence: ComponentDeclaration = {
  name: "image-sequence",
  definition: ImageSequenceDefinition,
};
export default ImageSequence;
