import React from "react";
import Overlay, { OverlayProps } from "./Overlay";
import Carousel from "react-material-ui-carousel";
import { Box, Grid, Typography } from "@mui/material";
import { StyleCollection } from "../Theme";

const styles: StyleCollection<
  "carousel" | "item" | "itemImage" | "body" | "heading"
> = {
  carousel: {},
  item: {
    textAlign: "center",
  },
  body: {
    fontSize: "1.1rem",
  },
  heading: {
    fontSize: "1.3rem",
  },
  itemImage: {
    width: 220,
    height: 220,
    objectFit: "contain",
    margin: "0 auto",
    padding: 2,
    "@media screen and (max-height: 625px)": {
      width: 150,
      height: 150,
    },
    "@media screen and (max-height: 550px)": {
      display: "none",
    },
  },
};

interface InstructionPanelProps {
  heading: string;
  imageSrc: string;
  textContent: string;
}

const InstructionPanel = (props: InstructionPanelProps): JSX.Element => {
  const { heading, imageSrc, textContent } = props;

  return (
    <Grid sx={styles.item} direction={"column"}>
      <Typography variant={"h2"} sx={styles.heading}>
        {heading}
      </Typography>
      <Box
        sx={styles.itemImage}
        component={"img"}
        src={imageSrc}
        alt={heading}
      />
      <Typography variant={"body1"} sx={styles.body}>
        {textContent}
      </Typography>
    </Grid>
  );
};

export type InstructionsOverlayProps = OverlayProps;

const InstructionsOverlay = (props: InstructionsOverlayProps): JSX.Element => {
  return (
    <Overlay {...props}>
      <Carousel
        sx={styles.carousel}
        autoPlay={false}
        animation={"slide"}
        swipe
        cycleNavigation={true}
        activeIndicatorIconButtonProps={{
          style: {
            color: "#DC6F23",
          },
        }}
      >
        <InstructionPanel
          heading={"Virtual Runway"}
          imageSrc={"/images/Instructions1.png"}
          textContent={
            "Scan the images of the models to see them come to life in our virtual fashion show"
          }
        />
        <InstructionPanel
          heading={"Win a Winter Wardrobe"}
          imageSrc={"/images/Instructions2.png"}
          textContent={
            "Enter your details after the experience for your chance to win a winter wardrode worth $5000!"
          }
        />
      </Carousel>
    </Overlay>
  );
};

export default InstructionsOverlay;
