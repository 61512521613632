import { ComponentDefinition } from "aframe";
import { ComponentDeclaration } from "./index";
import { Quaternion, Vector3 } from "three";

const targetNames = [
  "Poster 01",
  "Poster 02",
  "Poster 03",
  "Poster 04",
  "Poster 05",
];

export interface ImageTargetMockAttributes {
  tracking: boolean;
  index: number;
}
export const ImageTargetMockDefinition: ComponentDefinition<ImageTargetMockAttributes> =
  {
    tracking: false,
    index: 0,
    schema: {},
    init() {
      const scene = this.el.sceneEl!;
      scene.addEventListener("click", () => {
        const detail = {
          name: targetNames[this.index],
          type: "FLAT",
          position: new Vector3(0, 2.5, -2),
          rotation: new Quaternion(),
          scale: 1,
          scaledWidth: 0.75,
          scaledHeight: 1,
        };
        if (this.tracking) {
          scene.emit("xrimagelost", detail);
          this.index = (this.index + 1) % targetNames.length;
        } else {
          scene.emit("xrimagefound", detail);
        }
        this.tracking = !this.tracking;
      });
    },
  };

const ImageTargetMock: ComponentDeclaration = {
  name: "image-target-mock",
  definition: ImageTargetMockDefinition,
};
export default ImageTargetMock;
