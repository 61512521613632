import React, { ReactNode } from "react";
import { Button, Dialog, Grid } from "@mui/material";
import theme, { StyleCollection } from "../Theme";

const styles: StyleCollection<"overlay" | "header" | "body" | "action"> = {
  overlay: {
    pointerEvents: "auto",
    maxHeight: "calc(100vh - 100px)",
  },
  header: {
    padding: 1.5,
  },
  body: {
    bgcolor: "background.light",
    padding: theme.spacing(2, 5),
  },
  action: {
    borderRadius: 0,
  },
};

export interface OverlayProps {
  open: boolean;
  actionText?: string;
  onAction?: () => void;
  children?: ReactNode;
}

const Overlay = (props: OverlayProps): JSX.Element => {
  const { open, actionText = "Action", onAction, children } = props;

  return (
    <Dialog
      open={open}
      maxWidth={"xs"}
      fullWidth
      PaperProps={{
        sx: styles.overlay,
      }}
    >
      <Grid
        sx={styles.header}
        container
        direction={"column"}
        alignItems={"center"}
      >
        <img src="/icons/StocklandLogo.svg" alt={"Stockland Logo"} />
      </Grid>
      <Grid
        sx={styles.body}
        container
        direction={"column"}
        alignItems={"stretch"}
      >
        {children}
      </Grid>
      <Button
        variant={"contained"}
        sx={styles.action}
        onClick={onAction}
        disabled={!onAction}
      >
        {actionText}
      </Button>
    </Dialog>
  );
};

export default Overlay;
