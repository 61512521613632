import React, { ReactNode } from "react";
import { Button, Dialog, Grid, Typography } from "@mui/material";
import { StyleCollection } from "../Theme";

const styles: StyleCollection<
  | "overlay"
  | "header"
  | "body"
  | "action"
  | "titleContainer"
  | "lookButtonWrapper"
  | "lookButton"
  | "root"
> = {
  root: {
    zIndex: 1302,
  },
  overlay: {
    pointerEvents: "auto",
    margin: 0,
    width: "100%",
    height: "100%",
    maxHeight: "100%",
  },
  header: {
    padding: 1.5,
    position: "sticky",
    top: 0,
    backgroundColor: "rgba(255, 255, 255, 1)",
  },
  body: {
    flexGrow: 1,
  },
  action: {
    borderRadius: 0,
  },
  titleContainer: {
    justifyContent: "center",
  },
  lookButtonWrapper: {
    border: "1px solid #FFFFFF",
    borderRadius: 40,
    px: 2,
    py: 0.5,
  },
  lookButton: {
    padding: "2px",
    maxWidth: "180px",
    margin: "20px",
  },
};

export interface FullscreenOverlayProps {
  open: boolean;
  onClose?: () => void;
  title?: string;
  children: ReactNode;
}

const FullscreenOverlay = (props: FullscreenOverlayProps): JSX.Element => {
  const { open, onClose, title = "", children } = props;
  return (
    <Dialog open={open} fullScreen sx={styles.root}>
      <Grid sx={styles.header} container>
        <Grid>
          <Button>
            <img src="/icons/back.svg" alt={"Go Back"} onClick={onClose} />
          </Button>
        </Grid>
        <Grid sx={styles.titleContainer} container item xs={8}>
          <Typography variant="h2" alignSelf={"center"}>
            {title}
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="column" alignItems="stretch" item xs={12}>
        {children}
      </Grid>
    </Dialog>
  );
};

export default FullscreenOverlay;
