import ImageSequence from "./ImageSequence";
import { ComponentDefinition } from "aframe";
import TargetVideo from "./TargetVideo";
import ImageTargetMock from "./ImageTargetMock";
import VideoController from "./VideoController";

export interface ComponentDeclaration {
  name: string;
  definition: ComponentDefinition<any>;
}

export function registerComponents() {
  AFRAME.registerComponent(ImageSequence.name, ImageSequence.definition);
  AFRAME.registerComponent(ImageTargetMock.name, ImageTargetMock.definition);
  AFRAME.registerComponent(TargetVideo.name, TargetVideo.definition);
  AFRAME.registerComponent(VideoController.name, VideoController.definition);
}
