import React from "react";
import { StyleCollection } from "../Theme";
import { Box, Button, ButtonProps, SxProps } from "@mui/material";
import { Theme } from "@mui/material/styles";

const styles: StyleCollection<"buttonWrapper" | "button"> = {
  buttonWrapper: {
    border: "1px solid",
    borderColor: "inherit",
    borderRadius: 40,
    px: 2,
    py: 0.5,
  },
  button: {
    padding: "1px",
  },
};

export interface OutlineButtonProps extends ButtonProps {
  wrapperSx?: SxProps<Theme>;
}

const OutlineButton = (props: OutlineButtonProps): JSX.Element => {
  const { children, sx = [], wrapperSx = [], ...others } = props;

  return (
    <Button
      sx={[styles.button, ...(Array.isArray(sx) ? sx : [sx])]}
      {...others}
    >
      <Box
        sx={[
          styles.buttonWrapper,
          ...(Array.isArray(wrapperSx) ? wrapperSx : [wrapperSx]),
        ]}
      >
        {children}
      </Box>
    </Button>
  );
};

export default OutlineButton;
