import React from "react";
import { Box, Grid } from "@mui/material";
import HelpText from "./HelpText";
import { StyleCollection } from "../Theme";

const styles: StyleCollection<"helpText" | "scanningImage"> = {
  helpText: {
    height: 220,
    pt: 2.5,
  },
  scanningImage: {
    maxWidth: "75vw",
    maxHeight: "50vh",
  },
};

export interface ScanningBodyProps {}

const ScanningBody = (props: ScanningBodyProps): JSX.Element => {
  return (
    <Grid container direction={"column"} flexGrow={1}>
      <Grid
        container
        alignItems={"center"}
        justifyContent={"center"}
        item
        flexGrow={1}
        flexShrink={1}
      >
        <Box
          sx={styles.scanningImage}
          component="img"
          src={"/icons/Scan.svg"}
          alt={"Scan Area"}
        />
      </Grid>
      <Grid sx={styles.helpText}>
        <HelpText />
      </Grid>
    </Grid>
  );
};

export default ScanningBody;
