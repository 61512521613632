import React, { useEffect, useState } from "react";
import { Button, Grid } from "@mui/material";
import { StyleCollection } from "../Theme";
import FullscreenOverlay from "./FullscreenOverlay";

const styles: StyleCollection<
  "enterButtonWrapper" | "enterButton" | "articleContainer" | "articleFooter"
> = {
  articleContainer: {
    whiteSpace: "pre-line",
    fontSize: "15px",
    fontWeight: "250",
    padding: "20px",
  },
  articleFooter: {
    whiteSpace: "pre-line",
    fontSize: "13px",
    fontWeight: "150",
    padding: "20px",
  },
  enterButtonWrapper: {
    border: "1px solid #FFFFFF",
    borderRadius: 40,
    px: 2,
    py: 0.5,
    width: "100%",
    fontWeight: "bold",
  },
  enterButton: {
    padding: "2px",
    width: "280px",
    margin: "20px",
  },
};

export interface ArticleOverlayProps {
  open: boolean;
  onClose?: () => void;
  onCompetitionClick: () => void;
  contentName?: string;
}

const content = [
  {
    name: "Poster 01",
    title: "Trés chic! Parisian-inspired fashion for AW22",
    article: `Parisian chic combines elegance, grace, simplicity, and comfort. This look is sure to turn heads. It favours the understated light blue and beige shades — nonchalant and effortless.

    Accessories can really make or break an outfit, and our must-have, outfit-making shoe for winter is a knee-high boot. They can be styled with short, knee-length or midi length skirts and dresses. These boots provide warmth and style, which is necessary for any cool weather outfit. Bring a matching bag into the mix to make the look feel cohesive, while a beret is the final touch – not often a go-to hat but a conventional Parisian inclusion and surprisingly easy to style.
    
    The outfit consists of a three-piece set including an A-line skirt, crop and cardigan. The cardigan features accentuated shoulder details and is the perfect way to add a winter touch to the crop and skirt look. Matching knit sets are becoming the uniform of fashion-forward women. Life is better in a knit set – stay cosy and warm in style!`,
    footer: `For more everyday inspiration, updates on the latest trends and exclusive offers, register now as a Stockland Member to receive monthly updates, latest news and exclusive events from your local Stockland Shopping Centre. This information has been provided by our Stockland retailers. All reasonable care has been taken to ensure that the information is correct at the time of the content going live. However, no warranty is given as to the accuracy of that information. The information may change from time to time without notice. Stocks are limited and only available while stocks last with individual retailers. Products may vary from location to location. Prices may also change without notice. Please contact our retailers for more information.`,
    splashImage: "/images/parisian/splash.jpg",
    footerImage: "/images/parisian/footer.jpg",
  },
  {
    name: "Poster 02",
    title: "Dark chocolate: how to style autumn’s hottest colour trend!",
    article: `Autumn is a transitional season as we shift from warm nights to cool ones. The clothes we wear in this season consist of deep hues and earth tones and need to combat the confusing in-between weather.
    
    Kicking off this look is a cable knit brown set — the perfect addition to any wardrobe. The midi-length skirt and cardigan-like top are easy to style together or utilise separately. The chocolate hue is right on-trend, a minimalist favourite and perfect for autumn.
    
    Outerwear is essential for dressing a look up. This black leather jacket is a staple piece, still at the height of fashion, offering a sense of edginess and confidence. Black court heels and the pochette bag add cohesiveness to the look. Black and brown – once a style sin, now a style staple – work best together when avoiding any other bold colours, instead stick to a mix of neutral hues. We think black looks especially fab with shades of brown.
    
    Sleek hair and red lippy complete the look – a red carpet classic that has gained a lot of traction lately. Sleek hair is all about looking put together and neat while red lipstick screams 
    fun and embodies strength and poise.`,
    footer: `For more everyday inspiration, updates on the latest trends and exclusive offers, register now as a Stockland Member to receive monthly updates, latest news and exclusive events from your local Stockland Shopping Centre. This information has been provided by our Stockland retailers. All reasonable care has been taken to ensure that the information is correct at the time of the content going live. However, no warranty is given as to the accuracy of that information. The information may change from time to time without notice. Stocks are limited and only available while stocks last with individual retailers. Products may vary from location to location. Prices may also change without notice. Please contact our retailers for more information.`,
    splashImage: "/images/autumn/splash.jpg",
    footerImage: "/images/autumn/footer.jpg",
  },
  {
    name: "Poster 03",
    title: "How to nail double denim for AW22",
    article: `Double denim but make it elegant. The number one rule when it comes to double denim is not to dress head-to-toe in one shade — be sure to have a focal point. Red pumps are ideal for this retro style and the perfect statement accessory. Never underestimate the power of red pumps – they will always leave a lasting impression. 
    
    Oversized jackets, tight jeans, and acid wash was the ultimate “cool” look from the 90s — now, not so much. You can do double denim in unexpected ways. Avoid fashion faux pas by layering a thick white shacket over a basic tee. Replace the oversized denim jacket with a denim bag for a more subtle approach. Alternatively layer the shacket over a denim one — if it is extra chilly. This approach works best with a white clutch to tie in the shacket nd tee. Layering is key. 
    
    Add an extra touch of elegance by utilising gold jewellery. Layering gold chains of different lengths and thickness is quintessential to pull a look together. The final step is finding the perfect earrings to elevate the look, and classic gold hoops are the way to go. When the hoop earrings go in, the boss babe comes out.`,
    footer: `For more everyday inspiration, updates on the latest trends and exclusive offers, register now as a Stockland Member to receive monthly updates, latest news and exclusive events from your local Stockland Shopping Centre. This information has been provided by our Stockland retailers. All reasonable care has been taken to ensure that the information is correct at the time of the content going live. However, no warranty is given as to the accuracy of that information. The information may change from time to time without notice. Stocks are limited and only available while stocks last with individual retailers. Products may vary from location to location. Prices may also change without notice. Please contact our retailers for more information.`,
    splashImage: "/images/denim/splash.jpg",
    footerImage: "/images/denim/footer.jpg",
  },
  {
    name: "Poster 04",
    title: "Streetwear styles for the fashion-savvy teen",
    article: `Streetwear is a pop culture favourite, encompassing sportswear, skateboarding, surfing, and hip-hop elements. The rise of streetwear began in the 90s with all things oversized and edgy. We take an abbreviated approach to this trend by ‘winter-fying’ the style.

    Platform Doc Martens and the oversized blazer are pinnacle elements of the fit. They play the most prominent role in transforming the look into a streetwear inspired one. The beanie is a close second — enforcing an ultimate cool-girl vibe.
    
    Underneath the blazer, a plaid mini skirt takes centre stage. The revival of mini-skirts is going strong – this skirt is not only trendy but also practical. The wool fibres in the skirt allow for more warmth to be held in, ensuring you will be nice and toasty on those chilly days.
    
    Bold accessories make all the difference. The crossbody bag is the perfect balance between bold and simple, with the chunky strap playing into the edgy feel. Gold embellishments are the final touch — hoop earrings and a layered necklace is all that is needed to complete the look.`,
    footer: `For more everyday inspiration, updates on the latest trends and exclusive offers, register now as a Stockland Member to receive monthly updates, latest news and exclusive events from your local Stockland Shopping Centre. This information has been provided by our Stockland retailers. All reasonable care has been taken to ensure that the information is correct at the time of the content going live. However, no warranty is given as to the accuracy of that information. The information may change from time to time without notice. Stocks are limited and only available while stocks last with individual retailers. Products may vary from location to location. Prices may also change without notice. Please contact our retailers for more information.`,
    splashImage: "/images/street/splash.jpg",
    footerImage: "/images/street/footer.jpg",
  },
  {
    name: "Poster 05",
    title: "The great outdoors: how to wrap up for winter",
    article: `Practical, put together, and stylish – outdoor apparel continues to be right on trend in the fashion world, perfect for those upon an urban pursuit. Men’s fashion has gone through a lot of changes over time, and it can be hard to choose a style you love with so many options out there. However, if you are a lover of the outdoors and like a flare of masculinity in your clothing, this look is tailored just for you.

    This outdoor-inspired look consists of beige and black hues, details drawn from the natural environment. Beige is a star colour for menswear this year — to make the look feel warmer, we chose to pair it back with black touches, a staple colour that will never date.
    
    Functional layering, for insulation on those seriously chilly days, is also necessary for the look. Three layers are all you need to keep warm — the base, insulation, and a stylish outer layer. We used a neutral-toned tee for the base layer with a black sweatshirt on top. Finishing the look with a trusty windbreaker means you’ll be looking chic and cosy for all the winter days to come!
    
    The great outdoors look is all about versatility, and that is why Chuck Taylor All-Star Classic high-tops are the shoes of choice. These shoes are an all-time favourite with their unmistakable silhouette.
    
    Classic and stylish, this is a faultless outfit to bask in the winter sun and enjoy all that nature offers, even if it is just while walking the dog.`,
    footer: `For more everyday inspiration, updates on the latest trends and exclusive offers, register now as a Stockland Member to receive monthly updates, latest news and exclusive events from your local Stockland Shopping Centre. This information has been provided by our Stockland retailers. All reasonable care has been taken to ensure that the information is correct at the time of the content going live. However, no warranty is given as to the accuracy of that information. The information may change from time to time without notice. Stocks are limited and only available while stocks last with individual retailers. Products may vary from location to location. Prices may also change without notice. Please contact our retailers for more information.`,
    splashImage: "/images/outdoors/splash.jpg",
    footerImage: "/images/outdoors/footer.jpg",
  },
];

const ArticleOverlay = (props: ArticleOverlayProps): JSX.Element => {
  const { open, onClose, onCompetitionClick, contentName } = props;

  // Copying into state so that content doesn't change if a new item is scanned while open
  const [articleName, setArticleName] = useState<string>();

  const articleContent = content.find((item) => item.name === articleName);

  const handleActionClick = () => {
    setArticleName(undefined);
    onClose?.();
  };

  useEffect(() => {
    if (open && articleName === undefined) {
      setArticleName(contentName);
    }
  }, [contentName, articleName, open]);

  return (
    <FullscreenOverlay
      open={open}
      onClose={handleActionClick}
      title={"Get The Look"}
    >
      <img src={articleContent?.splashImage} width="100%" alt="Splash" />
      <Grid sx={styles.articleContainer}>
        <h3>{articleContent?.title}</h3>
        {articleContent?.article}
      </Grid>
      <img src={articleContent?.footerImage} width="100%" alt="Footer" />
      <Grid sx={styles.articleFooter}>{articleContent?.footer}</Grid>
      <Grid container justifyContent={"center"} padding={"20px"}>
        Click below for your chance to win a winter wardrode worth $5000!
        <Button
          sx={styles.enterButton}
          variant={"contained"}
          size={"small"}
          onClick={onCompetitionClick}
        >
          <Grid sx={styles.enterButtonWrapper} item>
            Enter now
          </Grid>
        </Button>
      </Grid>
    </FullscreenOverlay>
  );
};

export default ArticleOverlay;
