import "aframe-chromakey-material";

export const AR_DISABLED = process.env.REACT_APP_AR === "false";
const videoScale = "3 3 3";
const video1Offset = "0 -0.4 0";
const videoOffset = "0.02 -0.4 0";

const sceneHtml = `
<a-scene ${
  AR_DISABLED
    ? `image-target-mock
       background="color: #AAFFFF"
    `
    : `xrextras-almost-there
       xrextras-loading
       xrextras-runtime-error
       xrweb="disableWorldTracking: true"`
}>
    <a-assets>
        <video id="poster-1-1"
               muted
               playsinline
               crossorigin="anonymous"
               src="./videos/poster 1 green_1.mp4"></video>
        <video id="poster-1-2"
               muted
               playsinline
               crossorigin="anonymous"
               src="./videos/poster 1 green_2.mp4"></video>
        <video id="poster-2-1"
               muted
               playsinline
               crossorigin="anonymous"
               src="./videos/poster 2 green_1.mp4"></video>
        <video id="poster-2-2"
               muted
               playsinline
               crossorigin="anonymous"
               src="./videos/poster 2 green_2.mp4"></video>
        <video id="poster-3-1"
               muted
               playsinline
               crossorigin="anonymous"
               src="./videos/poster 3 green_1.mp4"></video>
        <video id="poster-3-2"
               muted
               playsinline
               crossorigin="anonymous"
               src="./videos/poster 3 green_2.mp4"></video>
        <video id="poster-4-1"
               muted
               playsinline
               crossorigin="anonymous"
               src="./videos/poster 04_green_1.mp4"></video>
        <video id="poster-4-2"
               muted
               playsinline
               crossorigin="anonymous"
               src="./videos/poster 04_green_2.mp4"></video>
        <video id="poster-5-1"
               muted
               playsinline
               crossorigin="anonymous"
               src="./videos/poster 05_pink_1.mp4"></video>
        <video id="poster-5-2"
               muted
               playsinline
               crossorigin="anonymous"
               src="./videos/poster 05_pink_2.mp4"></video>
    </a-assets>

    <a-entity light="type: directional;
                     castShadow: true;
                     color: white;
                     intensity: 0.5"
              position="5 10 7"></a-entity>

    <a-camera position="0 2 0"></a-camera>

    ${
      AR_DISABLED
        ? `
    <a-sphere position="0 2.5 -3"></a-sphere>
    <a-image src="#model-image"></a-image>
`
        : ""
    }

    <xrextras-named-image-target name="Poster 01">
        <a-entity scale="${videoScale}"
                  position="${video1Offset}"
                  video-controller="introVideo: #poster-1-1;
                                    outroVideo: #poster-1-2;
                                    imageTarget: Poster 01;">
            <a-entity target-video="video: #poster-1-1;"
                      material="shader: chromakey;
                                src: #poster-1-1;
                                color: 0.1 0.9 0.2;"
                      geometry="primitive: plane;
                                height: 1;
                                width: 0.675;"></a-entity>
            <a-entity target-video="video: #poster-1-2;"
                      material="shader: chromakey;
                                src: #poster-1-2;
                                color: 0.1 0.9 0.2;"
                      geometry="primitive: plane;
                                height: 1;
                                width: 0.675;"></a-entity>
        </a-entity>
    </xrextras-named-image-target>

    <xrextras-named-image-target name="Poster 02">
        <a-entity scale="${videoScale}"
                  position="${videoOffset}"
                  video-controller="introVideo: #poster-2-1;
                                    outroVideo: #poster-2-2;
                                    imageTarget: Poster 02;">
            <a-entity target-video="video: #poster-2-1;"
                      material="shader: chromakey;
                                src: #poster-2-1;
                                color: 0.1 0.9 0.2;"
                      geometry="primitive: plane;
                                height: 1;
                                width: 0.675;"></a-entity>
            <a-entity target-video="video: #poster-2-2;"
                      material="shader: chromakey;
                                src: #poster-2-2;
                                color: 0.1 0.9 0.2;"
                      geometry="primitive: plane;
                                height: 1;
                                width: 0.675;"></a-entity>
        </a-entity>
    </xrextras-named-image-target>

    <xrextras-named-image-target name="Poster 03">
        <a-entity scale="${videoScale}"
                  position="${videoOffset}"
                  video-controller="introVideo: #poster-3-1;
                                    outroVideo: #poster-3-2;
                                    imageTarget: Poster 03;">
            <a-entity target-video="video: #poster-3-1;"
                      material="shader: chromakey;
                                src: #poster-3-1;
                                color: 0.1 0.9 0.2;"
                      geometry="primitive: plane;
                                height: 1;
                                width: 0.675;"></a-entity>
            <a-entity target-video="video: #poster-3-2;"
                      material="shader: chromakey;
                                src: #poster-3-2;
                                color: 0.1 0.9 0.2;"
                      geometry="primitive: plane;
                                height: 1;
                                width: 0.675;"></a-entity>
        </a-entity>
    </xrextras-named-image-target>

    <xrextras-named-image-target name="Poster 04">
        <a-entity scale="${videoScale}"
                  position="${videoOffset}"
                  video-controller="introVideo: #poster-4-1;
                                    outroVideo: #poster-4-2;
                                    imageTarget: Poster 04;">
            <a-entity target-video="video: #poster-4-1;"
                      material="shader: chromakey;
                                src: #poster-4-1;
                                color: 0.1 0.9 0.2;"
                      geometry="primitive: plane;
                                height: 1;
                                width: 0.675;"></a-entity>
            <a-entity target-video="video: #poster-4-2;"
                      material="shader: chromakey;
                                src: #poster-4-2;
                                color: 0.1 0.9 0.2;"
                      geometry="primitive: plane;
                                height: 1;
                                width: 0.675;"></a-entity>
        </a-entity>
    </xrextras-named-image-target>

    <xrextras-named-image-target name="Poster 05">
        <a-entity scale="${videoScale}"
                  position="${videoOffset}"
                  video-controller="introVideo: #poster-5-1;
                                    outroVideo: #poster-5-2;
                                    imageTarget: Poster 05;">
            <a-entity target-video="video: #poster-5-1;"
                      material="shader: chromakey;
                                src: #poster-5-1;
                                color: 1 0 0.73;"
                      geometry="primitive: plane;
                                height: 1;
                                width: 0.675;"></a-entity>
            <a-entity target-video="video: #poster-5-2;"
                      material="shader: chromakey;
                                src: #poster-5-2;
                                color: 1 0 0.73;"
                      geometry="primitive: plane;
                                height: 1;
                                width: 0.675;"></a-entity>
        </a-entity>
    </xrextras-named-image-target>

    <a-plane height="2000"
             width="2000"
             rotation="-90 0 0"
             material="shader: shadow;
                       opacity: 0.67"
             shadow></a-plane>

    <!-- configure capture settings -->
    <xrextras-capture-config
            watermark-max-width="100"
            watermark-max-height="10"
            file-name-prefix="AW22-"
            request-mic="manual"
    ></xrextras-capture-config>

    <!-- add capture preview -->
    <xrextras-capture-preview></xrextras-capture-preview>
</a-scene>`;

export default sceneHtml;
