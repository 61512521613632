import { ComponentDefinition } from "aframe";
import { ComponentDeclaration } from "./index";

export interface TargetVideoAttributes {}
export const TargetVideoDefinition: ComponentDefinition<TargetVideoAttributes> =
  {
    schema: {
      video: { type: "string" },
    },
    init() {
      const v = document.querySelector(
        this.data.video
      ) as unknown as HTMLVideoElement;

      const { el } = this;

      const showImage = () => {
        el.setAttribute("visible", true);
        // console.log(`Video ${this.data.video} shown`);
        v.play();
      };
      const hideImage = () => {
        // console.log(`Video ${this.data.video} hidden`);
        v.currentTime = 0;
        v.pause();
        el.setAttribute("visible", false);
      };

      // pre-play video as fix for black box bug (Android Only)
      v.play().then(() => {
        hideImage();
      });

      el.parentNode?.addEventListener(`show-${this.data.video}`, showImage);
      el.parentNode?.addEventListener(`hide-${this.data.video}`, hideImage);
    },
  };

const TargetVideo: ComponentDeclaration = {
  name: "target-video",
  definition: TargetVideoDefinition,
};
export default TargetVideo;
