import React, { useCallback, useEffect, useState } from "react";
import AFrameScene from "./aframe/AframeScene";
import AScene, { AR_DISABLED } from "./AScene";
import { Grid } from "@mui/material";
import { StyleCollection } from "./Theme";
import InstructionsOverlay from "./components/InstructionsOverlay";
import { isMobile } from "react-device-detect";
import Header from "./components/Header";
import ScanningBody from "./components/ScanningBody";
import PlayingBody from "./components/PlayingBody";
import ArticleOverlay from "./components/Article";
import AppState, { TAppState } from "./AppState";
import EnterCompetition from "./components/EnterCompetition";

declare global {
  interface Window {
    XR8: any;
  }
}

const styles: StyleCollection<"scene" | "ui"> = {
  scene: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  ui: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    px: 5,
    py: 4,
    pointerEvents: "none",
  },
};

const ArState = {
  loading: "loading",
  ready: "ready",
  error: "error",
} as const;

type TArState = typeof ArState[keyof typeof ArState];

function App() {
  const [state, setState] = useState<TAppState>(AppState.Instructions);
  const [arState, setArState] = useState<TArState>(ArState.loading);
  const [imageName, setImageName] = useState<string>();
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [showArticle, setShowArticle] = useState(false);
  const [showCompetition, setShowCompetition] = useState(false);

  const updateState = useCallback(
    (newState: TAppState) => {
      const ascene = document.querySelector("a-scene");

      if (newState !== state) {
        const was = state;

        setState(newState);

        const detail = {
          state: newState,
          was,
          tracking: imageName,
        };
        console.log(`Emitting state-changed`, detail);

        ascene.emit("state-changed", detail);
      }
    },
    [state, imageName]
  );

  const handleStateUpdate = (event: any) => {
    const { state: newState, was } = event.detail;
    console.log(`Received state-update`, event.detail);

    // make sure transition is sensible, and we're not listening to our own events.
    if (newState !== state && was === state) {
      updateState(newState);
    }
  };

  const handleArReady = (event: any) => {
    console.log(`Scene Loaded`);
    setArState(ArState.ready);
  };

  const handleArError = (event: any) => {
    console.log(`Scene Loaded`);
    setArState(ArState.error);
  };

  const handleInstructionsOpen = () => {
    updateState(AppState.Instructions);
  };

  const handleInstructionsClose = () => {
    updateState(AppState.Scanning);
  };

  const handleImageFound = (event: any) => {
    console.log(`imageFound [${JSON.stringify(event.detail)}]`);
    // Don't track a new image if we're already tracking
    if (!imageName) {
      setImageName(event.detail.name);
    }
  };

  const handleImageLost = (event: any) => {
    console.log(`imageLost [${JSON.stringify(event.detail)}]`);
    setImageName(undefined);
  };

  const handlePreviewOpen = () => {
    setIsPreviewOpen(true);
  };

  const handlePreviewClose = () => {
    setIsPreviewOpen(false);
  };

  const openArticle = () => {
    setShowArticle(true);
    setShowCompetition(false);
  };

  const openCompetition = () => {
    setShowArticle(false);
    setShowCompetition(true);
  };

  const handleNextClick = () => {
    updateState(AppState.Outro);
  };

  // Change state on image found/lost
  useEffect(() => {
    if (imageName && state === AppState.Scanning) {
      updateState(AppState.Intro);
    }
    if (
      !imageName &&
      !showArticle &&
      !showCompetition &&
      (state === AppState.Intro ||
        state === AppState.Pose ||
        state === AppState.Outro)
    ) {
      updateState(AppState.Scanning);
    }
  }, [imageName, state, updateState, showArticle, showCompetition]);

  useEffect(() => {
    const interval = setInterval(() => {
      console.log(
        `XR8 ${!window.XR8 ? "Doesn't Exist" : "Exists"}`,
        window.XR8
      );

      if (window.XR8) {
        window.XR8.addCameraPipelineModule({
          name: "camera-error-detector",
          onCameraStatusChange: ({ status }: { status: string }) => {
            if (status === "failed") {
              setArState(ArState.error);
            }
          },
        });

        clearInterval(interval);
      }
    }, 10);
  }, []);

  // Register event listeners
  useEffect(() => {
    const ascene = document.querySelector("a-scene");

    ascene.addEventListener("xrimagefound", handleImageFound);
    ascene.addEventListener("xrimagelost", handleImageLost);
    ascene.addEventListener("state-update", handleStateUpdate);

    const readyEvent = AR_DISABLED ? "renderstart" : "realityready";
    const errorEvent = AR_DISABLED ? "there's no error event" : "realityerror";
    ascene.addEventListener(readyEvent, handleArReady);
    ascene.addEventListener(errorEvent, handleArError);

    window.addEventListener("mediarecorder-photocomplete", handlePreviewOpen);
    window.addEventListener("mediarecorder-previewclosed", handlePreviewClose);

    return () => {
      ascene.removeEventListener("xrimagefound", handleImageFound);
      ascene.removeEventListener("xrimagelost", handleImageLost);
      ascene.removeEventListener("state-update", handleStateUpdate);
      ascene.removeEventListener(readyEvent, handleArReady);
      ascene.removeEventListener(errorEvent, handleArError);

      window.removeEventListener(
        "mediarecorder-photocomplete",
        handlePreviewOpen
      );
      window.removeEventListener(
        "mediarecorder-previewclosed",
        handlePreviewClose
      );
    };
  });

  let instructionButtonText: string;

  switch (arState) {
    case "loading":
      instructionButtonText = "Loading...";
      break;
    case "ready":
      instructionButtonText = "Start";
      break;
    case "error":
      instructionButtonText = "Error";
      break;
  }

  return (
    <>
      <AFrameScene sx={styles.scene} sceneHtml={AScene} />
      {(isMobile || AR_DISABLED) && (
        <Grid
          sx={styles.ui}
          container
          direction={"column"}
          alignItems={"center"}
          wrap={"nowrap"}
        >
          {state !== AppState.Instructions && !isPreviewOpen && (
            <Header onInfoClick={handleInstructionsOpen} />
          )}
          {state === AppState.Scanning && <ScanningBody />}
          {(state === AppState.Intro ||
            state === AppState.Pose ||
            state === AppState.Outro) && (
            <PlayingBody
              state={state}
              onArticleClick={openArticle}
              onCompetitionClick={openCompetition}
              onNextClick={handleNextClick}
            />
          )}

          <ArticleOverlay
            open={showArticle}
            onClose={() => setShowArticle(false)}
            onCompetitionClick={openCompetition}
            contentName={imageName}
          />
          <EnterCompetition
            open={showCompetition}
            onClose={() => setShowCompetition(false)}
          />
          <InstructionsOverlay
            open={state === AppState.Instructions && arState !== ArState.error}
            actionText={instructionButtonText}
            onAction={
              arState === ArState.ready ? handleInstructionsClose : undefined
            }
          />
        </Grid>
      )}
    </>
  );
}

export default App;
