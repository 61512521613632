import React from "react";
import FullscreenOverlay from "./FullscreenOverlay";

interface PropsEnterCompetition {
  open: boolean;
  onClose: () => void;
}

const EnterCompetition = (props: PropsEnterCompetition): JSX.Element => {
  const { open, onClose } = props;
  return (
    <FullscreenOverlay open={open} onClose={onClose} title="Enter competition">
      <div className="enter-competition">
        <iframe
          sandbox="allow-scripts allow-forms"
          allowFullScreen
          src="https://pub.promo.stockland.com.au/ShowForm.ashx?FormGuid=051be7cd-a80c-42c8-b534-226ddcca8175"
          frameBorder="0"
          title="Registration"
        />
      </div>
    </FullscreenOverlay>
  );
};

export default EnterCompetition;
