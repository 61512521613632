import React from "react";
import { Button, Grid } from "@mui/material";
import { StyleCollection } from "../Theme";
import OutlineButton from "./OutlineButton";
import AppState, { TAppState } from "../AppState";

const styles: StyleCollection<
  | "buttonGroup"
  | "cameraButton"
  | "nextButton"
  | "lookButtonWrapper"
  | "lookButton"
> = {
  buttonGroup: {
    height: 250,
  },
  cameraButton: {
    p: 2,
    my: 4,
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.7)",
    },
  },
  nextButton: {
    border: "1px solid rgba(138, 138, 138, 1)",
    padding: "1px",
    backgroundColor: "rgba(225, 224, 224, 1)",
    "&:hover": {
      backgroundColor: "rgba(191,191,191, 1)",
    },
  },
  lookButtonWrapper: {
    border: "1px solid #FFFFFF",
    borderRadius: 40,
    px: 2,
    py: 0.5,
    fontWeight: "bold",
  },
  lookButton: {
    padding: "2px",
  },
};

export interface PlayingBodyProps {
  state: TAppState;
  onArticleClick: () => void;
  onCompetitionClick: () => void;
  onNextClick: () => void;
}

const PlayingBody = (props: PlayingBodyProps): JSX.Element => {
  const { state, onArticleClick, onCompetitionClick, onNextClick } = props;

  const handleScreenshotClick = () => {
    // @ts-ignore
    window.XR8.CanvasScreenshot.takeScreenshot().then((data: string) => {
      fetch(`data:image/jpeg;base64,${data}`)
        .then((resp) => resp.blob())
        .then((blob) => {
          window.dispatchEvent(
            new CustomEvent("mediarecorder-photocomplete", { detail: { blob } })
          );
        });
    });
  };

  return (
    <Grid container direction={"column"} flexGrow={1}>
      <Grid
        container
        flexGrow={1}
        justifyContent={"center"}
        alignItems={"flex-end"}
      >
        {(state === AppState.Pose || state === AppState.Outro) && (
          <Button
            sx={styles.lookButton}
            variant={"contained"}
            size={"small"}
            onClick={onArticleClick}
          >
            <Grid sx={styles.lookButtonWrapper} item>
              Get the look
            </Grid>
          </Button>
        )}
      </Grid>
      <Grid
        sx={styles.buttonGroup}
        container
        direction={"column"}
        alignItems={"center"}
      >
        <Grid container alignItems={"center"}>
          <Grid item xs={4} />
          <Grid item container xs={4} justifyContent={"center"}>
            <Button
              sx={styles.cameraButton}
              variant={"outlined"}
              color={"secondary"}
              onClick={handleScreenshotClick}
            >
              <img src={"/icons/photo_camera.svg"} alt={"Take a screenshot"} />
            </Button>
          </Grid>
          <Grid container item xs={4}>
            {state === AppState.Pose && (
              <OutlineButton
                variant={"outlined"}
                size={"small"}
                color={"secondary"}
                onClick={onNextClick}
                sx={styles.nextButton}
              >
                Next &gt;
              </OutlineButton>
            )}
          </Grid>
        </Grid>
        {state === AppState.Outro && (
          <Button
            sx={styles.lookButton}
            variant={"contained"}
            size={"small"}
            onClick={onCompetitionClick}
          >
            <Grid sx={styles.lookButtonWrapper} item>
              Enter now
            </Grid>
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default PlayingBody;
