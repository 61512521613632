import aframeComponents from "./aframeComponents";
import React, { useEffect } from "react";
import { Box } from "@mui/material";

// A React component for 8th Wall AFrame scenes. The scene HTML can be supplied, along with
// any components or primitives that should be registered, and any image targets that should be
// loaded if something other than the automatically loaded set is wanted. Passing
// DISABLE_IMAGE_TARGETS will prevent any image targets from loading, including ones that would
// otherwise enabled automatically.

// A react component for loading and unloading an aframe scene. The initial scene contents should
// be specified as an html string in sceneHtml. All props must be specified when the component
// mounts. Updates to props will be ignored.
//
// Optionally, aframe coponents to register for this scene can be passed as [{name, val}] arrays.
// Care is needed here to not define the same component different across scenes, since aframe
// components can't be unloaded.
//
// Optionally imageTargets can be specified to override the set loaded by default.

export interface AFrameSceneProps {
  sceneHtml: string;
}

function AFrameScene(props: AFrameSceneProps & Parameters<typeof Box>[0]) {
  const { sceneHtml, ...others } = props;

  useEffect(() => {
    aframeComponents();

    const container = document.getElementById("scene-container");
    container!.innerHTML = sceneHtml;

    // Cleanup
    return () => {
      const ascene = document.getElementsByTagName("a-scene")[0];
      container!.removeChild(ascene);
    };
  }, [sceneHtml]);

  return <Box {...others} id="scene-container" />;
}

export default AFrameScene;
