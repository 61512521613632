import { createTheme } from "@mui/material";
import { SystemStyleObject } from "@mui/system/styleFunctionSx/styleFunctionSx";

export type StyleCollection<T extends string> = {
  [key in T]: SystemStyleObject;
};

declare module "@mui/material/styles" {
  interface TypeBackground {
    light: string;
  }
}

export default createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 40,
          pointerEvents: "auto",
        },
        sizeSmall: {
          fontSize: "1.125rem", // 18px
          fontWeight: 300,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 0,
        },
      },
    },
  },
  palette: {
    primary: {
      main: "rgba(220, 111, 35, 1)",
    },
    secondary: {
      main: "#000000",
    },
    background: {
      light: "rgba(247, 184, 125, 0.25)",
    },
    text: {
      primary: "#000000",
      secondary: "#FFFFFF",
    },
  },
  typography: {
    fontFamily: [
      "National PL",
      "Open Sans",
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    h1: {
      fontSize: "1.75rem", // 28px
      fontWeight: 600,
      textAlign: "center",
    },
    h2: {
      fontSize: "1.5rem", // 24px
      fontWeight: 600,
      textAlign: "center",
    },
    body1: {
      fontSize: "1.125rem", // 18px
      fontWeight: 300,
      textAlign: "center",
    },
    button: {
      fontSize: "1.5rem", // 24px
      fontWeight: 600,
      textTransform: "none",
    },
  },
});
