import { registerComponents } from "./components";

let registered = false;

export default function aframeComponents() {
  if (registered) {
    console.log("Attempted to register components a second time");
    return;
  }
  console.log("Registering Components");
  registered = true;

  registerComponents();
}
