const AppState = {
  Instructions: "Introduction",
  Scanning: "Scanning",
  Intro: "Intro",
  Pose: "Pose",
  Outro: "Outro",
} as const;

export default AppState;
export type TAppState = typeof AppState[keyof typeof AppState];
