import React from "react";
import { Box, Typography } from "@mui/material";

export interface HelpTextProps {}

const HelpText = (props: HelpTextProps): JSX.Element => {
  return (
    <Box>
      <Typography variant={"h2"}>Scan Poster</Typography>
      <Typography variant={"body1"}>
        Scan the image of the model to start the virtual fashion show
      </Typography>
    </Box>
  );
};

export default HelpText;
