import React from "react";
import { StyleCollection } from "../Theme";
import { Grid, Typography } from "@mui/material";

const styles: StyleCollection<
  "body" | "info" | "titleContainer" | "title" | "subtitle"
> = {
  body: {
    pb: 8,
    zIndex: 1301,
  },
  info: {
    pointerEvents: "auto",
    justifyContent: "flex-start",
  },
  titleContainer: {
    justifyContent: "center",
  },
  title: {
    pointerEvents: "auto",
    lineHeight: 1,
  },
  subtitle: {
    pointerEvents: "auto",
    lineHeight: 1,
  },
};

export interface HeaderProps {
  onInfoClick: () => void;
}

const Header = (props: HeaderProps): JSX.Element => {
  const { onInfoClick } = props;
  return (
    <Grid sx={styles.body} container>
      <Grid sx={styles.info} container item xs={2}>
        <img src="/icons/Info.svg" alt="thing" onClick={onInfoClick} />
      </Grid>

      <Grid
        sx={styles.titleContainer}
        container
        item
        xs={8}
        direction={"column"}
      >
        <Typography sx={styles.title} variant={"h1"}>
          The Looks
        </Typography>
        <Typography sx={styles.subtitle} variant={"body1"}>
          AW22 Fashion
        </Typography>
      </Grid>
      <Grid container item xs={2} />
    </Grid>
  );
};

export default Header;
