import { ComponentDefinition } from "aframe";
import { ComponentDeclaration } from "./index";
import AppState, { TAppState } from "../../AppState";

export interface TargetVideoAttributes {
  appState: TAppState;
}
export const VideoControllerDefinition: ComponentDefinition<TargetVideoAttributes> =
  {
    appState: AppState.Instructions,
    schema: {
      imageTarget: { type: "string" },
      introVideo: { type: "string" },
      outroVideo: { type: "string" },
    },
    init() {
      const { el, data } = this;
      const { imageTarget, introVideo, outroVideo } = data;

      const introElement = document.querySelector(
        data.introVideo
      ) as unknown as HTMLVideoElement;
      const outroElement = document.querySelector(
        data.outroVideo
      ) as unknown as HTMLVideoElement;

      if (!introElement || !outroElement) {
        console.error(
          `One of the videos was not found when initialising: intro[${!!introElement}] outro[${!!outroElement}]`
        );
        return;
      }

      const handleStateChanged = (event: any) => {
        const { state, tracking } = event.detail;
        this.appState = state;

        if (tracking === imageTarget) {
          console.log(`Controller ${imageTarget} received state update`);

          switch (this.appState as TAppState) {
            case AppState.Intro:
              el.emit(`show-${introVideo}`);
              break;
            case AppState.Pose:
              break;
            case AppState.Outro:
              el.emit(`hide-${introVideo}`);
              el.emit(`show-${outroVideo}`);
              break;
            default:
              el.emit(`hide-${introVideo}`);
              el.emit(`hide-${outroVideo}`);
              break;
          }
        } else {
          el.emit(`hide-${introVideo}`);
          el.emit(`hide-${outroVideo}`);
        }
      };

      const handleIntroEnd = () => {
        if (this.appState === AppState.Intro) {
          el.sceneEl?.emit("state-update", {
            state: AppState.Pose,
            was: this.appState,
            tracking: this.data.imageTarget,
          });
        }
      };

      const handleResume = () => {
        if (this.appState === AppState.Pose) {
          el.sceneEl?.emit("state-update", {
            state: AppState.Outro,
            was: this.appState,
            tracking: this.data.imageTarget,
          });
        }
      };

      const handleOutroEnd = () => {
        el.emit(`hide-${outroVideo}`);
      };

      el.sceneEl?.addEventListener("state-changed", handleStateChanged);
      el.sceneEl?.addEventListener("resume", handleResume);
      introElement.addEventListener("ended", handleIntroEnd);
      outroElement.addEventListener("ended", handleOutroEnd);
    },
  };

const VideoController: ComponentDeclaration = {
  name: "video-controller",
  definition: VideoControllerDefinition,
};
export default VideoController;
